import React from "react";

const Contact = () => {
  return (
    <section className="bg-primary px-5 py-32 h-screen snap-start" id="contact">
      <div className="text-center md:w-[60%] mx-auto text-white">
        <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[200px] mx-auto border-cyan-400 pb-2">
          Contact Me
        </h2>
        <p>
          Currently looking for work as a software developer
        </p>

        <p className="py-2">
          <span className="font-bold">Email:</span> Porter.killian@gmail.com
        </p>
       
      </div>
    </section>
  );
};

export default Contact;