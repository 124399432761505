import React,  { useState }  from "react";
import HeroImg from "../assets/hero.png";

import {
  AiOutlineLinkedin,
  AiOutlineGithub,
  
} from "react-icons/ai";

const Hero = () => {
  

  return (
    <section className="bg-primary px-5 text-white py-32 h-screen snap-start">
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between h-full"> {/* Add h-full class to the parent div */}
        <div className="hero-info pb-5 md:pb-0">
          <h1 className="text-4xl lg:text-6xl">
            Hi, <br />I am Porter Killian<br />
            A software engineer
          </h1>


          <div className="flex py-5 ">
            <a
              href="https://www.linkedin.com/in/porter27/"
              className="pr-4 inline-block text-accent hover:text-white"
            >
              {" "}
              <AiOutlineLinkedin size={40} />{" "}
            </a>
            <a
              href="https://www.github.com"
              className="pr-4 inline-block text-accent hover:text-white"
            >
              {" "}
              <AiOutlineGithub size={40} />{" "}
            </a>
            
          </div>

          <a
            href="/#projects"
            className="btn bg-accent text-white px-6 py-3 hover:bg-transparent"
          >
            See Projects
          </a>
        </div>

        <div className="hero-img ">
          <img
            src={HeroImg}
            alt="headshot"
            className="lgw-[80%] h-1/2 ml-auto"
          />
        </div>
        <div className="mobile-hero left-[-100%]" >
        <img
            src={HeroImg}
            alt="headshot"
            className="h-1/2 ml-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
