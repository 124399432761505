import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ image, title, onClose }) => {
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [scaledImage, setScaledImage] = useState(image);

  useEffect(() => {
    // Create a new image element
    const img = new Image();
    img.src = image;

    // When the image loads, determine its orientation
    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const horizontal = imgWidth > imgHeight;
      setIsHorizontal(horizontal);

      // Calculate the scaling factor
      let scaleFactor;

      if (horizontal) {
        // Horizontal image, scale to 80% of screen width
        scaleFactor = (window.innerWidth * 0.8) / imgWidth;
      } else {
        // Vertical image, scale to 80% of screen height
        scaleFactor = (window.innerHeight * 0.2) / imgHeight;
      }

      // Apply the scaling factor to the image's width and height
      const scaledWidth = imgWidth * scaleFactor;
      const scaledHeight = imgHeight * scaleFactor;

      // Update the image source with the scaled dimensions
      setScaledImage(
        `${image}?w=${scaledWidth}&h=${scaledHeight}&fit=crop&auto=format`
      );
    };
  }, [image]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          <AiOutlineClose className="close-icon" />
        </button>
        <div className="modal-inner">
          <img src={scaledImage} alt={title} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
