import React, { useState } from "react";

import Slider from "./Slider"

const Art = () => {
  const post = [
    {
      img: "/art/IMG_0460.jpg",
      title: "Tokyo, Japan",
      type: "photos"
    },
    {
      img: "/art/IMG_0547.jpg",
      title: "Tokyo, Japan",
      type: "photos"
    },
    {
      img: "/art/IMG_2622.jpg",
      title: "San Jaun, Puerto Rico",
      type: "photos"
    },
    {
      img: "/art/IMG_7933.jpg",
      title: "Philadelphia, Pa",
      type: "photos"
    },
    {
    img: "/art/IMG_7645.jpg",
    title: "Philadelphia, Pa",
    type: "photos"
    },
    {
      img: "/art/IMG_4750.jpg",
      title: "Morea, French Polenesia",
      type: "photos"
    },
    {
      img: "/art/IMG_4637.jpg",
      title: "Morea, French Polenesia",
      type: "photos"
    },
    {
      img: "/art/IMG_6109.JPG",
      title: "Saint Petersburg, Fl",
      type: "photos"
    },
    {
      img: "/art/IMG_7612.jpg",
      title: "Calculated Risk",
      type: "drawings"
    },
    {
      img: "/art/IMG_7886.jpg",
      title: "Connected",
      type: "drawings"
    },
    {
      img: "/art/IMG_8044.jpg",
      title: "Space oddity 2020",
      type: "drawings"
    },
    {
      img: "/art/IMG_7731.jpg",
      title: "College living",
      type: "drawings"
    },
    {
      img: "/art/1_1.jpg",
      title: "Sound as Art",
      type: "designs"
    },
    {
      img: "/art/1_2.jpg",
      title: "Sound as Art(Final)",
      type: "designs"
    },
    {
      img: "/art/2_1.jpg",
      title: "Poetry as Art",
      type: "designs"
    },
    {
      img: "/art/2_2.jpg",
      title: "Poetry as Art(Final)",
      type: "designs"
    },
    {
      img: "/art/radio.jpg",
      title: "Photo Radio",
      type: "designs"
    },
    {
      img: "/art/shoes.jpg",
      title: "Waling Shoes",
      type: "designs"
    },
  ];

  const [filter, setFilter] = useState("all"); // Initial filter state

  const filteredPosts = filter === "all" ? post : post.filter(item => item.type === filter);

  return (
    <section className="bg-secondary text-white px-5 py-32 h-screen snap-start" id="art">
      <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
        <div className="about-info mb-5">
          <div className="flex items-center"> {/* Use flex to align title and buttons */}
            <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[100px] border-cyan-400 pb-2">
              Art
            </h2>
            <div className="btn-group ml-3"> {/* Add button group */}
              <button
                className={`btn ${
                  filter === "all" ? "bg-accent" : "bg-transparent"
                } mr-3`}
                onClick={() => setFilter("all")}
              >
                All
              </button>
              <button
                className={`btn ${
                  filter === "photos" ? "bg-accent" : "bg-transparent"
                } mr-3`}
                onClick={() => setFilter("photos")}
              >
                Photos
              </button>
              <button
                className={`btn ${
                  filter === "drawings" ? "bg-accent" : "bg-transparent"
                } mr-3`}
                onClick={() => setFilter("drawings")}
              >
                Drawings
              </button>
              <button
                className={`btn ${
                  filter === "designs" ? "bg-accent" : "bg-transparent"
                } mr-3`}
                onClick={() => setFilter("designs")}
              >
                Designs
              </button>
            </div>
          </div>
          <p className="pb-5">Throughout my college career, I’ve found that the work I’ve enjoyed the most comes at the intersection of my programming and art interests – making something that is esthetically pleasing and technologically advanced.</p>
        </div>
        <div></div>
      </div>
      <div className="projects container mx-auto ">
        <Slider projects={filteredPosts} />
      </div>
    </section>
  );
};

export default Art;
