import "./App.css";
import React from "react";
import Header from "./components/Header.js";
import Hero from "./components/Hero";
import About from "./components/About";
import Project from "./components/Project";
import Art from "./components/Art";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <div className="snap-y snap-mandatory h-screen overflow-y-scroll ">
        <Hero />
        <About />
        <Project />
        <Art />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
