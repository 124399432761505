import React from "react";
import AboutImg from "../assets/about-img.jpg";

const About = () => {
  return (
    <section className="bg-secondary text-white px-5 py-32 h-screen snap-start" id="about" >
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
        <div className="about-info">
          <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-cyan-400 pb-2">
            About Me
          </h2>

          <p className="pb-5">
          I am a recent graduate from the University of Pennsylvania, where I earned a Bachelor of Applied Science (BAS) degree in computer science in 2023. My time at Penn has been an incredible journey, filled with challenging coursework and exciting projects that have shaped my passion for technology and problem-solving.
            
          </p>

          <p className="pb-5">
          I have a strong foundation in software development, starting with Java. I excel in database management, including SQL, NoSQL, and Spark, often combined with Python for data analysis.            </p>

          <p className="pb-5">
          I'm passionate about web development, using JavaScript and React with Tailwind CSS. I have used C++ primarily for computer graphics and animation, and I'm also proficient in graphic design, utilizing Adobe Photoshop and Illustrator          </p>
        </div>

        <div className="about-img">
          <img
            src={AboutImg}
            alt="headshot"
            className="lgw-[80%] md:ml-auto mx-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default About;