 
  import React from "react";
  import ProjectSlider from "./ProjectSlider";

  
  const Projects = () => {
    const projects = [
        {
          img: "/projects/shackle.png",
          title: "Shackleco.com",
          desc: "Solo developer for an online market place website built from scratch using the Materialize framework, JS, and Firebase hosting. The final website includes processing payment(Stripe API) and an automated mailing system(NodeJS through firebase).",
          youtube: "none",
          code: "https://github.com/PKill27/ShackleCo",
        },
        {
          img: "/projects/mc.png",
          title: "Mini-minecraft",
          desc: "3 person final project for cis 460( computer graphics), Coded in C++. Together me and my group implemented many features such as: terrain generation(procedurally generated), player movement, sheep + AI for sheep, Trees and different biomes.",
          youtube: "kj2i6JXxjOc",
          code: "none",
        },
        {
          img: "/projects/localmedia.png",
          title: "Local Media",
          desc: "Final project for android development in Kotlin, the project is a social media app in which pictures are taken at a location and the pictures that are uploaded near the current location can be viewed based off of the maximum distance set.",
          code: "https://github.com/PKill27/Andriod-Kotlin",
          youtube: "rse81GGyq-4",
        },
        {
          img: "/projects/2048.png",
          title: "2048 reinforcment learning",
          desc: "Final project for machine learning course, Coded in python. Used multiple different approaches of reinforcement learning to try and play 2048 effectively.",
          youtube: "aacsC9dmVHw",
          code: "none",
        },
        {
          img: "/projects/pacman.png",
          title: "Pacman",
          desc: "Freshman fall final project, implemented paceman from scratch in java. Included high score functionality, ghost ai, and collision detection. ",
          youtube: "none",
          code: "https://github.com/PKill27/Pacman",
        },
      ];;
  
    return (
      <section className="bg-primary text-white px-5 py-32 h-screen snap-start" id="projects">
        <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
          <div className="about-info mb-5">
            <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-cyan-400 pb-2">
              Projects
            </h2>
  
            <p className="pb-5">
              These are some of my best projects. I have made with a vareity of technologies. 
            </p>
          </div>
  
          <div className="about-img"></div>
        </div>
  
        <div className="projects container mx-auto ">
        
          <ProjectSlider projects={projects} />
        </div>
      </section>
    );
  };
  
  export default Projects;