// Modal.js
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const ModalProjects = ({ image, title, desc, youtube, code, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          <AiOutlineClose className="close-icon" />
        </button>
        <div className="modal-inner">
          {youtube !== "none" ? (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${youtube}`}
              title={title}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <img src={image} alt={title} />
          )}
          <h2>{desc}</h2>
          {code !== "none" && (
             <a
             href={code} target="_blank" rel="noopener noreferrer"
             className="btn bg-accent text-white px-6 py-3 hover:bg-transparent"
           >
             Veiw Code
           </a>
            
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalProjects;
